import { CustomFieldComponentType } from '~/components/CustomField'
import {
  CustomFieldFormType,
  CustomFieldResponseItem
} from '../types/custom-field'

export const mappingTypeToFieldKind = (type: string): string => {
  const dataMapping: { [key: string]: string } = {
    text: 'string',
    paragraph: 'text',
    number: 'number',
    toggle: 'boolean',
    select: 'array',
    date: 'date',
    multiple: 'multiple'
  }
  return dataMapping?.[type] || 'string'
}

export const mappingCustomFieldKind = (
  fieldKind: string
): CustomFieldComponentType['type'] => {
  const dataMapping: { [key: string]: CustomFieldComponentType['type'] } = {
    string: 'text',
    text: 'paragraph',
    number: 'number',
    boolean: 'toggle',
    array: 'select',
    date: 'date',
    multiple: 'multiple'
  }
  return dataMapping?.[fieldKind] || 'text'
}

export const renderKeyCustomFieldForm = ({
  fieldKind,
  id
}: {
  fieldKind: string
  id: number | string
}) => `${id}-${fieldKind}`

export const formatInitialValueCustomField = (
  data?: CustomFieldResponseItem[],
  extraConfig?: {
    isNotTrimText: boolean
  }
): CustomFieldFormType['customFields'] => {
  return (data || []).reduce((result, item) => {
    const customFieldType = mappingCustomFieldKind(item.fieldKind)
    return {
      ...result,
      [renderKeyCustomFieldForm({
        fieldKind: item.fieldKind,
        id: item.customSettingId
      })]: {
        ...item,
        id: item.customSettingId,
        type: customFieldType,
        value: transformValue({
          type: customFieldType,
          data: item,
          extraConfig
        })
      }
    }
  }, {})
}

export const formatSubmitCustomFieldData = (
  data?: CustomFieldFormType['customFields'],
  extraConfig?: {
    isNotTrimText: boolean
  }
) => {
  return Object.values(data || {}).map((item) => {
    return {
      index: Number(item.index),
      fieldKind: mappingTypeToFieldKind(item.type),
      customSettingId: Number(item.id),
      value:
        item.type === 'date'
          ? Object.keys(item.value || {}).length
            ? item.value
            : { year: null, month: null, date: null }
          : item.type === 'select' || item.type === 'multiple'
          ? ''
          : typeof item.value === 'string' && !extraConfig?.isNotTrimText
          ? item.value.trim()
          : item.value || '',
      selectedOptionKeys:
        item.type === 'select'
          ? [item.value]
          : item.type === 'multiple'
          ? item.value
          : []
    }
  })
}

const transformValue = ({
  type,
  data,
  extraConfig
}: {
  type: string
  data: CustomFieldResponseItem
  extraConfig?: {
    isNotTrimText: boolean
  }
}) => {
  switch (type) {
    case 'date':
      return Object.keys(data.value || {}).length
        ? data.value
        : { year: null, month: null, date: null }
    case 'select':
      return data.selectedOptionKeys?.[0]
    case 'multiple':
      return data.selectedOptionKeys
    default:
      return typeof data.value === 'string' && !extraConfig?.isNotTrimText
        ? (data.value || '').trim()
        : data.value
  }
}
